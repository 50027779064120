import { Formik } from 'formik';
import * as React from 'react';
import { FC, useContext, useState } from 'react';
import { Button, PhoneInputField, TextInput, Typography } from '@/components';
import {
  CheckboxField,
  Flex,
  Grid,
  Radio,
  RadioGroupField,
  View
} from '@aws-amplify/ui-react';
import { useStyles } from './styles';
import { AssetsContext } from '@/GlobalProvider/GlobalProvider';
import { convertAgeToDate, convertDateToAge } from '@/utils';
import { useNavigate, useParams } from 'react-router-dom';
import {
  convertFtToInches,
  convertInchesToFt,
  covertCmToFt,
  onePnInKg
} from './calculations.helper';
import { getSchema } from './schema';
import { useCreateUpdateCustomerForm } from './create-update-customer-form.helper';
import * as classNames from 'classnames';
import {
  CustomerAccounts,
  Genders,
  GenderSelectionFlags,
  HeightWeightUnits
} from '@/constants';
import { RadioSwitchGroup } from '@/components/RadioSwitchGroup';

export interface User {
  id?: string;
  email: string;
  phone: string;
  firstName: string;
  lastName: string;
  dateOfBirth: number;
  gender: string;
  height: number;
  weight: number;
}

export interface UserCreateData extends User {
  termsAndConditions?: boolean;
  accountId: string;
  heightWeightUnit: HeightWeightUnits;
}

const getInitialValues = (gender = 'male'): UserCreateData => ({
  email: '',
  phone: '',
  firstName: '',
  lastName: '',
  dateOfBirth: null,
  gender,
  height: null,
  weight: null,
  termsAndConditions: false,
  accountId: null,
  heightWeightUnit: HeightWeightUnits.LbsFtInches
});

interface CreateUpdateCustomerForm {
  submitHandler: (data: UserCreateData) => Promise<void>;
  isUpdateForm?: boolean;
  defaultValue?: User;
  isQuickUpdate?: boolean;
}

const getIsEmailRequired = (customer_account_handle: string) => {
  switch (customer_account_handle) {
    case CustomerAccounts.EmailOnly:
      return true;
    case CustomerAccounts.EmailRequiredPhoneOptional:
      return true;
    case CustomerAccounts.EmailAndPhone:
      return true;
    default:
      return false;
  }
};

const getIsPhoneRequired = (customer_account_handle: string) => {
  switch (customer_account_handle) {
    case CustomerAccounts.PhoneOnly:
      return true;
    case CustomerAccounts.EmailAndPhone:
      return true;
    default:
      return false;
  }
};

const singUpMessages = {
  email_or_phone: 'Phone number or email address are mandatory for sign up.',
  email_only: 'Email address is mandatory for sign up.',
  phone_only: 'Phone number is mandatory for sign up.',
  email_and_phone: 'Phone number or email address are mandatory for sign up.',
  email_required_phone_optional: 'Email address is mandatory for sign up.'
};

const getUpdateMessages = (hasPhone: boolean, hasEmail: boolean) => {
  if (hasPhone && hasEmail) {
    return {
      email_or_phone: 'Phone number and email address cannot be updated.',
      email_only: 'Email address cannot be updated.',
      phone_only: 'Phone number cannot be updated.',
      email_and_phone: 'Phone number and email address cannot be updated.',
      email_required_phone_optional:
        'Phone number and email address cannot be updated.'
    };
  }

  if (hasPhone) {
    return {
      email_or_phone: 'Phone number cannot be updated.',
      email_only: 'Email address cannot be updated.',
      phone_only: 'Phone number cannot be updated.',
      email_and_phone: 'Phone number cannot be updated.',
      email_required_phone_optional:
        'Phone number and email address cannot be updated.'
    };
  }

  if (hasEmail) {
    return {
      email_or_phone: 'Email address cannot be updated.',
      email_only: 'Email address cannot be updated.',
      phone_only: 'Phone number cannot be updated.',
      email_and_phone: 'Phone number and email address cannot be updated.',
      email_required_phone_optional: 'Email address cannot be updated.'
    };
  }

  return {};
};

const errorMessages = {
  email_or_phone: 'Phone number or email address is required',
  email_only: 'Email address is required',
  phone_only: 'Phone number is required',
  email_and_phone: 'Phone number and email address is required',
  email_required_phone_optional: 'Email address is required'
};

const getBothInputs = (customer_account_handle) => {
  switch (customer_account_handle) {
    case CustomerAccounts.EmailOrPhone:
      return true;
    case CustomerAccounts.EmailAndPhone:
      return true;
    case CustomerAccounts.EmailRequiredPhoneOptional:
      return true;
    default:
      return false;
  }
};

export const CreateUpdateCustomerForm: FC<CreateUpdateCustomerForm> = ({
  submitHandler,
  isUpdateForm,
  defaultValue
}) => {
  const styles = useStyles();
  const navigate = useNavigate();
  const params = useParams();

  const {
    asset_list: {
      terms_and_conditions_url,
      privacy_policy_url,
      gender_select_label
    },
    behavior_list: {
      gender_selection_flag,
      customer_account_handle,
      display_i_agree_checkbox
    }
  } = useContext(AssetsContext);

  const onlyPhone = customer_account_handle === CustomerAccounts.PhoneOnly;
  const onlyEmail = customer_account_handle === CustomerAccounts.EmailOnly;
  const showPhoneInput = onlyPhone || getBothInputs(customer_account_handle);
  const showEmailInput = onlyEmail || getBothInputs(customer_account_handle);

  const updateMessages = getUpdateMessages(
    !!defaultValue?.phone,
    !!defaultValue?.email
  );

  const errorMessage = errorMessages[customer_account_handle];
  const userMessageForSignUp = singUpMessages[customer_account_handle];
  const userMessageForUpdate = updateMessages[customer_account_handle];

  const userMessage = isUpdateForm
    ? userMessageForUpdate
    : userMessageForSignUp;

  const [countryCode, setCountryCode] = useState<{
    countryCode: string;
    dialCode: string;
  }>({ countryCode: '', dialCode: '' });
  const [isLoading, setIsLoading] = useState(false);

  // convert config "true" or "false" string to boolean
  const show_i_agree_checkbox_boolean = display_i_agree_checkbox === 'true';

  const defaultGender =
    gender_selection_flag === GenderSelectionFlags.AssumedMale
      ? Genders.Male
      : gender_selection_flag === GenderSelectionFlags.AssumedFemale
      ? Genders.Female
      : null;

  const onSubmit = async (data) => {
    setIsLoading(true);
    let { feet, inch, weight } = data;

    if (data.heightWeightUnit === HeightWeightUnits.KgCm) {
      [feet, inch] = covertCmToFt(data.height);
      weight = weight * onePnInKg;
    }

    const keys = Object.keys(data);
    keys.forEach((key) => {
      if (typeof data[key] === 'string') {
        data[key] = data[key].replaceAll(/\s+/g, '');
      }
    });

    if (data.email) {
      data.email = data.email.toLowerCase();
    } else {
      data.email = '';
    }

    await submitHandler({
      ...data,
      dateOfBirth: convertAgeToDate(data.dateOfBirth),
      email: data.email.toLowerCase(),
      height: convertFtToInches(feet, inch),
      weight: Math.round(weight),
      phone: data.phone === countryCode.dialCode ? '' : data.phone
    });
    setIsLoading(false);
  };

  const termsAndConditionsLabel = (
    <div>
      I accept{' '}
      <a target="_blank" href={terms_and_conditions_url} rel="noreferrer">
        Terms of Use
      </a>{' '}
      and{' '}
      <a target="_blank" href={privacy_policy_url} rel="noreferrer">
        Privacy Policy
      </a>
      .
    </div>
  );

  const initialValues = getInitialValues(defaultGender || Genders.Male);

  return (
    <Formik
      initialValues={
        defaultValue
          ? {
              ...defaultValue,
              gender: !defaultValue.gender
                ? defaultGender
                : defaultValue.gender, // if not defined, use defaultgender
              weight: !defaultValue.weight ? null : defaultValue.weight, // if not defined, set to null
              height: !defaultValue.height ? null : defaultValue.height, // if not defined, set to null
              dateOfBirth: convertDateToAge(defaultValue.dateOfBirth),
              ...convertInchesToFt(defaultValue.height),
              heightWeightUnit: HeightWeightUnits.LbsFtInches
            }
          : { ...initialValues, ...convertInchesToFt(initialValues.height) }
      }
      validationSchema={getSchema(
        countryCode,
        errorMessage,
        getIsEmailRequired(customer_account_handle),
        getIsPhoneRequired(customer_account_handle),
        customer_account_handle === CustomerAccounts.EmailRequiredPhoneOptional,
        customer_account_handle === CustomerAccounts.ThirdPartyAccountId,
        show_i_agree_checkbox_boolean
      )}
      onSubmit={onSubmit}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        errors,
        touched,
        setFieldValue
      }) => {
        const {
          ftInputRef,
          inInputRef,
          cmInputRef,
          weightInputRef,
          ageInputRef,
          emailInputRef
        } = useCreateUpdateCustomerForm({
          setFieldValue,
          values
        });

        return (
          <Grid
            columnGap="3rem"
            rowGap={{ base: '0', medium: '0.5rem' }}
            templateColumns="1fr 1fr 1fr 1fr 1fr 1fr"
          >
            {customer_account_handle !==
              CustomerAccounts.ThirdPartyAccountId && (
              <>
                <View columnSpan={[6, 6, 3]}>
                  <TextInput
                    label="First Name"
                    type="text"
                    name="firstName"
                    placeholder="Enter first name"
                    value={values.firstName}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      e.target.value = e.target.value.replace(/\s/g, '');
                      handleChange(e);
                    }}
                    hasError={touched.firstName && !!errors.firstName}
                    errorMessage={errors.firstName as string}
                  />
                </View>
                <View columnSpan={[6, 6, 3]}>
                  <TextInput
                    label="Last Name"
                    name="lastName"
                    placeholder="Enter last name"
                    value={values.lastName}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      e.target.value = e.target.value.replace(/\s/g, '');
                      handleChange(e);
                    }}
                    hasError={touched.lastName && !!errors.lastName}
                    errorMessage={errors.lastName as string}
                  />
                </View>
              </>
            )}
            {customer_account_handle ===
              CustomerAccounts.ThirdPartyAccountId && (
              <View columnSpan={[6, 6, onlyPhone ? 6 : 3]}>
                <TextInput
                  label="Subject ID"
                  name="accountId"
                  placeholder="Enter Subject ID"
                  value={values.accountId?.toLowerCase()}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    e.target.value = e.target.value.replace(/\s/g, '');
                    handleChange(e);
                  }}
                  hasError={touched.accountId && !!errors.accountId}
                  errorMessage={errors.accountId as string}
                  disabled={!!isUpdateForm}
                />
              </View>
            )}
            {showPhoneInput && (
              <View columnSpan={[6, 6, onlyPhone ? 6 : 3]}>
                <PhoneInputField
                  value={values.phone ? values.phone : ''}
                  error={errors.phone as string}
                  touched={touched.phone as boolean}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  disabled={!!isUpdateForm && !!defaultValue?.phone}
                  setCountryCode={setCountryCode}
                />
              </View>
            )}
            {showEmailInput && (
              <View columnSpan={[6, 6, onlyEmail ? 6 : 3]}>
                <TextInput
                  ref={emailInputRef}
                  label="Email Address"
                  type="email"
                  name="email"
                  placeholder="Enter email address"
                  value={values.email ? values.email.toLowerCase() : ''}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    e.target.value = e.target.value.replace(/\s/g, '');
                    handleChange(e);
                  }}
                  hasError={touched.email && !!errors.email}
                  errorMessage={errors.email as string}
                  disabled={!!isUpdateForm && !!defaultValue?.email}
                />
              </View>
            )}
            <View
              columnStart={
                customer_account_handle ===
                CustomerAccounts.EmailRequiredPhoneOptional
                  ? { base: 1, medium: onlyEmail ? 1 : 4 }
                  : 1
              }
              columnEnd={7}
            >
              <Typography component="h6">{userMessage}</Typography>
            </View>
            <View columnSpan={[6, 6, 3]}>
              {!defaultGender && (
                <RadioGroupField
                  className={'custom-input'}
                  label={
                    <label style={{ minHeight: '27px' }}>
                      {gender_select_label}
                    </label>
                  }
                  name="gender"
                >
                  <Flex className={'custom-radio-buttons'}>
                    <Radio
                      checked={values.gender === Genders.Male}
                      name="gender"
                      value={Genders.Male}
                      onChange={handleChange}
                    >
                      Male
                    </Radio>
                    <Radio
                      checked={values.gender === Genders.Female}
                      name="gender"
                      value={Genders.Female}
                      onChange={handleChange}
                    >
                      Female
                    </Radio>
                  </Flex>
                </RadioGroupField>
              )}
              <TextInput
                ref={ageInputRef}
                label="Age"
                placeholder="Age"
                type="text"
                name="dateOfBirth"
                value={values.dateOfBirth}
                className={styles.ageInput}
                onBlur={handleBlur}
                hasError={touched.dateOfBirth && !!errors.dateOfBirth}
                errorMessage={errors.dateOfBirth as string}
                onChange={(e) => {
                  if (e.target.value === '' || /^\d+$/g.test(e.target.value)) {
                    handleChange(e);
                  } else {
                    e.target.value = values.dateOfBirth;
                  }
                }}
              />
            </View>
            <View columnSpan={[6, 6, 3]}>
              <RadioSwitchGroup
                name={'heightWeightUnit'}
                handleChange={handleChange}
                values={[HeightWeightUnits.LbsFtInches, HeightWeightUnits.KgCm]}
                value={values.heightWeightUnit}
                label={'Weight and Height Inputs'}
                subLabels={[
                  HeightWeightUnits.LbsFtInches,
                  HeightWeightUnits.KgCm
                ]}
              />

              <Flex alignItems={'end'}>
                <Flex alignItems={'center'}>
                  <TextInput
                    ref={weightInputRef}
                    label="Weight"
                    type="text"
                    placeholder={
                      values.heightWeightUnit === HeightWeightUnits.LbsFtInches
                        ? 'Lb'
                        : 'Kg'
                    }
                    className={styles.input}
                    value={values.weight && Math.round(values.weight)}
                    name="weight"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      if (
                        e.target.value === '' ||
                        /^\d+$/g.test(e.target.value)
                      ) {
                        handleChange(e);
                      } else {
                        e.target.value = values.dateOfBirth;
                      }
                    }}
                    hasError={touched.weight && !!errors.weight}
                    errorMessage={errors.weight as string}
                  />
                  <span className={styles.subLabel}>
                    {values.heightWeightUnit === HeightWeightUnits.LbsFtInches
                      ? 'Lb'
                      : 'Kg'}
                  </span>
                </Flex>
                {values.heightWeightUnit === HeightWeightUnits.KgCm ? (
                  <Flex alignItems={'center'}>
                    <TextInput
                      ref={cmInputRef}
                      label="Height"
                      type="text"
                      placeholder="Cm"
                      className={styles.input}
                      name="height"
                      value={values.height && Math.round(values.height)}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        if (
                          e.target.value === '' ||
                          /^\d+$/g.test(e.target.value)
                        ) {
                          handleChange(e);
                        } else {
                          e.target.value = values.dateOfBirth;
                        }
                      }}
                      hasError={touched.height && !!errors.height}
                      errorMessage={errors.height as string}
                    />
                    <span className={styles.subLabel}>Cm</span>
                  </Flex>
                ) : (
                  <Flex alignItems={'end'}>
                    <Flex alignItems={'center'}>
                      <TextInput
                        ref={ftInputRef}
                        label="Height"
                        type="text"
                        placeholder="Ft"
                        name="feet"
                        value={values.feet}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          if (
                            e.target.value === '' ||
                            /^\d+$/g.test(e.target.value)
                          ) {
                            handleChange(e);
                          } else {
                            e.target.value = values.dateOfBirth;
                          }
                        }}
                        hasError={touched.feet && !!errors.feet}
                        errorMessage={errors.feet as string}
                        className={styles.heightSmallInput}
                      />
                      <span className={styles.subLabel}>Ft</span>
                    </Flex>
                    <Flex alignItems={'center'}>
                      <TextInput
                        ref={inInputRef}
                        label=""
                        type="text"
                        placeholder="In"
                        name="inch"
                        value={values.inch && Math.round(values.inch)}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          if (
                            e.target.value === '' ||
                            /^\d+$/g.test(e.target.value)
                          ) {
                            handleChange(e);
                          } else {
                            e.target.value = values.dateOfBirth;
                          }
                        }}
                        hasError={touched.inch && !!errors.inch}
                        errorMessage={errors.inch as string}
                        className={classNames(
                          styles.heightSmallInput,
                          styles.heightSmallInputWithoutLabel
                        )}
                      />
                      <span className={styles.subLabel}>In</span>
                    </Flex>
                  </Flex>
                )}
              </Flex>
            </View>
            {!isUpdateForm && show_i_agree_checkbox_boolean && (
              <View
                columnSpan="6"
                className={`${styles.termsAndConditions} custom-checkbox`}
              >
                <CheckboxField
                  value={''}
                  label={termsAndConditionsLabel}
                  name="termsAndConditions"
                  hasError={
                    touched.termsAndConditions && !!errors.termsAndConditions
                  }
                  onChange={handleChange}
                  required
                  errorMessage="You must agree before submitting."
                />
              </View>
            )}
            <View
              columnSpan="6"
              className={classNames(
                styles.buttonContainer,
                isUpdateForm && styles.buttonContainerPadding
              )}
            >
              <Button
                isLoading={isLoading}
                buttonType="secondary"
                text={isUpdateForm ? 'Update' : 'Sign up'}
                className={styles.submitButton}
                onClick={() => handleSubmit()}
              />
            </View>
          </Grid>
        );
      }}
    </Formik>
  );
};
